<template>
  <div class="admin-create-smart-button">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">New Smart Button</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoadingAnything" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- Add from mobile app -->
    <div class="add-from-mobile-app" v-if="!isLoadingAnything">
      <p>To add a personal button, add it from the teamSOS mobile app.</p>
    </div>
    <!-- / Add from mobile app -->

    <!-- Form -->
    <div v-if="!isLoadingAnything" class="form">
      <a-form-item label="Button Ownership">
        <a-input size="large" disabled placeholder="Organisational"></a-input>
      </a-form-item>

      <a-form-item label="Button Type">
        <a-select
          size="large"
          style="width: 100%"
          v-model="smartButtonLocal.formFactorId"
        >
          <a-select-option
            :value="factor.id"
            v-for="factor in triggerFormFactorsToShow"
            :key="factor.id"
          >
            {{ factor.displayName }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="Display Name">
        <a-input size="large" v-model="smartButtonLocal.displayName"></a-input>
      </a-form-item>

      <a-form-item label="Default Location">
        <a-select
          size="large"
          style="width: 100%"
          v-model="smartButtonLocal.locationId"
        >
          <a-select-option v-for="location in locations" :key="location.id">
            {{ location.displayName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </div>
    <!-- / Form -->

    <!-- Trigger -->
    <div class="form">
      <p><b>Single click:</b> {{ singleClickUrl }}</p>
      <p><b>Double click:</b> {{ doubleClickUrl }}</p>
      <p style="margin-bottom: 0"><b>Hold down:</b> {{ holdDownUrl }}</p>
    </div>
    <!-- / Trigger -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import externalTriggers from "../../../../api/external-triggers";

export default {
  components: { LoadingScreen },

  data() {
    return {
      smartButtonLocal: {
        formFactorId: null,
        displayName: "",
        locationId: null,
        uniqueId: "",
      },

      isSaving: false,
    };
  },

  created() {
    let vm = this;

    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }

    if (!(this.triggerFormFactors && this.triggerFormFactors.length)) {
      this.loadTriggerFormFactors()
        .then(() => {
          if (
            !vm.smartButtonLocal.formFactorId &&
            vm.triggerFormFactorsToShow.length
          ) {
            vm.smartButtonLocal.formFactorId = _.first(
              vm.triggerFormFactorsToShow
            ).id;
            vm.$forceUpdate();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (
        !vm.smartButtonLocal.formFactorId &&
        vm.triggerFormFactorsToShow.length
      ) {
        vm.smartButtonLocal.formFactorId = _.first(
          vm.triggerFormFactorsToShow
        ).id;
        vm.$forceUpdate();
      }
    }

    if (!this.smartButtonLocal.locationId && this.locations.length) {
      this.smartButtonLocal.locationId = this.locations[0].id;
    }

    this.smartButtonLocal.uniqueId = this.genUniqueId();
  },

  methods: {
    ...mapActions("adminSmartButtons", {
      loadTriggerFormFactors: "loadTriggerFormFactors",
    }),

    goBack() {
      this.$router.push("/admin/integration/smart-buttons");
    },

    randomIntFromInterval(min, max) {
      // min and max included
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    genUniqueId() {
      let vals = "abcdefghijklmnopqrstuvwxyz0123456789".split("");
      let uid = "";
      for (let i = 0; i < 10; i++) {
        let pos = this.randomIntFromInterval(0, vals.length - 1);
        uid = uid + vals[pos];
      }
      return uid;
    },

    // Saving
    getValidationErrors() {
      let errors = [];
      if (this.smartButtonLocal.displayName.trim().length == 0) {
        errors.push("Please provide a display name");
      }
       if (!this.smartButtonLocal.locationId) {
        errors.push("Please select a default location");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.smartButtonLocal,
        ownerId: this.selectedOrganisation.id
      };

      externalTriggers
        .addTriggerSource(vm.tenantId, params)
        .then(async () => {
          vm.$message.success("Smart button created successfully");
          vm.$router.push("/admin/integration/smart-buttons");
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error('Error creating smart button');
          vm.isSaving = false;
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
      locations: "locations",
      myPresenceId: "myPresenceId"
    }),

    ...mapGetters("adminSmartButtons", {
      triggerFormFactors: "triggerFormFactors",
      isLoadingTriggerFormFactors: "isLoadingTriggerFormFactors",
    }),

    triggerFormFactorsToShow() {
      return _.filter(this.triggerFormFactors, (tff) => {
        return tff.allowAnonymousWebTrigger;
      });
    },

    isLoadingAnything() {
      return this.isLoading || this.isLoadingTriggerFormFactors;
    },

    baseUrl() {
      if (window.activeProfile == "live") {
        return (
          "https://api.teamsos.co.uk/api/v1/ts/" +
          this.smartButtonLocal.uniqueId
        );
      } else {
        return (
          "https://api.testing.teamsos.co.uk/api/v1/ts/" +
          this.smartButtonLocal.uniqueId
        );
      }
    },

    singleClickUrl() {
      return this.baseUrl + "/1";
    },

    doubleClickUrl() {
      return this.baseUrl + "/2";
    },

    holdDownUrl() {
      return this.baseUrl + "/3";
    },
  },
};
</script>

<style scoped lang="scss">
.admin-create-smart-button {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
    margin-bottom: 15px;
    &.last {
      margin-bottom: 0;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 5px;
  }
}
</style>